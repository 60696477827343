import { encryptWithKey, decryptWithKey, decrypt } from '@/helpers/encrypt'

const STREAM = 'api/request'

const DB_NAME = 'PROOFOFID'
const TABLE = 'KEYS'
const KEY_NAMES = {
  privateKey: 'PRIVATE_KEY',
  publicKey: 'PUBLIC_KEY'
}

export default {
  namespaced: true,
  state: {
    items: [],
    requests: []
  },
  getters: {},
  mutations: {
    setNewRequests (state, newRequests) {
      state.requests = newRequests
    }
  },
  actions: {
    async fetchRequests ({ rootState, dispatch }) {
      return await dispatch('fetchDataWithParams', { stream: 'api/request/list', params: { responder_id: rootState.auth.authId, page: '1' } }, { root: true })
    },
    async fetchRequestsByRequestorID ({ rootState, dispatch }, { page, limit }) {
      return await dispatch('fetchDataWithParams', { stream: 'api/request/list/by-requestor', params: { requestor_id: rootState.auth.authId, page, limit } }, { root: true })
    },
    async createRequest ({ rootState, dispatch }) {
      const request = {
        responderId: rootState.auth.authId,
        deviceId: localStorage.getItem('DEVICE_ID')
      }
      return await dispatch('createData', { stream: STREAM + '/create', body: request }, { root: true })
    },
    async startNotificationListener ({ rootState, dispatch }, { callbackError, callbackRequest, callbackResponse, callbackWebsocketClosed }) {
      const client = rootState.auth.authId
      if (client) {
        return await dispatch('startWebSocket', {
          callbackError,
          callbackRequest,
          callbackResponse,
          callbackWebsocketClosed,
          deviceID: localStorage.getItem('DEVICE_ID'),
          clientID: client
        }, { root: true })
      }
    },
    async sendNotification ({ rootState, dispatch }, request) {
      return await dispatch('sendNotificationRequest', { requestID: request, clientID: rootState.auth.authId }, { root: true })
    },
    async sendDecision ({ rootState, dispatch }, { request, approval, includeIds, details, requestorId, requestorDeviceId }) {
      const decision = await dispatch('sendNotificationDecision', {
        requestID: request.id,
        clientID: rootState.auth.authId,
        approval,
        includeIds,
        details
      }, { root: true })

      if (approval) {
        await dispatch('transferData', { includeIds, requestorId, requestorDeviceId, requestId: request.id })
      }

      return decision
    },
    async transferData ({ rootState, dispatch }, { includeIds, requestorId, requestorDeviceId, requestId }) {
      const key = await dispatch('getPublicKey', { requestorId, requestorDeviceId }, { root: true })
      const documentsRaw = await dispatch('documentsLs/getItems', null, { root: true })
      let transferDataObj = {
        ...documentsRaw.fields
      }
      if (includeIds) {
        transferDataObj = documentsRaw
      }
      const ecryptedData = await encryptWithKey(transferDataObj, key.publicKey)
      const delivery = {
        requestId,
        deliveryData: ecryptedData
      }
      return await dispatch('createData', { stream: 'api/delivery/create', body: delivery }, { root: true })
    },
    async decryptDataTransferred ({ rootState, dispatch }, requestId) {
      const privateEncryptedKey = await dispatch('getByUserId', { database: DB_NAME, table: TABLE, id: KEY_NAMES.privateKey, userId: rootState.auth.authId }, { root: true })
      const publicEncryptedKey = await dispatch('getByUserId', { database: DB_NAME, table: TABLE, id: KEY_NAMES.publicKey, userId: rootState.auth.authId }, { root: true })
      const privateKey = await decrypt(rootState, privateEncryptedKey.data)
      const publicKey = await decrypt(rootState, publicEncryptedKey.data)
      const delivery = await dispatch('fetchDataWithURL', { stream: 'api/delivery', params: requestId }, { root: true })
      return await decryptWithKey(rootState.auth.authId, delivery.deliveryData, privateKey, publicKey)
    }
  }
}
