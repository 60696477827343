export const findById = (resources, id) => {
  if (!resources) return null
  return resources.find(r => r.id === id)
}

export const findByUserId = (resources, UserId) => {
  if (!resources) return null
  return resources.find(r => r.UserId === UserId)
}

export const checkResourceHasProps = (request, properties) => {
  // Check if request is not null and is properties is
  //  an array
  let isOkay = Array.isArray(properties) && request
  properties.forEach(prop => {
    isOkay = isOkay && Object.prototype.hasOwnProperty.call(request, prop) && (request[prop].toString() !== '')
  })

  return isOkay
}

export const extractTimestamp = (date) => {
  if (!(date instanceof Date)) {
    throw new Error('Invalid input: Please provide a valid Date object.')
  }

  return {
    seconds: Math.floor(date.getTime() / 1000)
  }
}

export const upsert = (resources, resource) => {
  const index = resources.findIndex(p => p.id === resource.id)
  if (resource.id && index !== -1) {
    resources[index] = resource
  } else {
    resources.push(resource)
  }
}
export const remove = (resources, resource) => {
  const index = resources.findIndex(p => p.id === resource.id)
  if (resource.id && index !== -1) {
    resources[index] = resource
  } else {
    resources.push(resource)
  }
}
export const docToResource = (doc) => {
  if (typeof doc?.data !== 'function') return doc
  return { ...doc.data(), id: doc.id }
}
export const makeAppendChildToParentMutation = ({ parent, child }) => {
  return (state, { childId, parentId }) => {
    const resource = findById(state.items, parentId)
    if (!resource) {
      console.warn(`Appending ${child} ${childId} to ${parent} ${parentId} failed because the parent didn't exist`)
      return
    }
    resource[child] = resource[child] || []

    if (!resource[child].includes(childId)) {
      resource[child].push(childId)
    }
  }
}

export const makeFetchItemAction = ({ emoji, resource }) => {
  return ({ dispatch }, payload) => dispatch('fetchItem', { emoji, resource, ...payload }, { root: true })
}
export const makeFetchItemsAction = ({ emoji, resource }) => {
  return ({ dispatch }, payload) => dispatch('fetchItems', { emoji, resource, ...payload }, { root: true })
}

export const arrayRandom = (array) => {
  const randomIndex = Math.floor(Math.random() * array.length)
  return array[randomIndex]
}
