const STREAM = 'api'

export default {
  namespaced: true,
  state: {
    items: [],
    values: null,
    options: []
  },
  getters: {},
  actions: {
    async visionOcrId ({ commit, state, dispatch }, imageBase64) {
      return await dispatch('createData', { stream: `${STREAM}/ocr/id`, body: { image_base64: imageBase64 } }, { root: true })
    }
  },
  mutations: {}
}
