import { generatePair, encrypt } from '@/helpers/encrypt'

const DB_NAME = 'PROOFOFID'
const TABLE = 'KEYS'
const KEY_NAMES = {
  privateKey: 'PRIVATE_KEY',
  publicKey: 'PUBLIC_KEY'
}

const STREAM = 'auth'

export default {
  namespaced: true,
  state: {
    user: {},
    session: '',
    accessToken: '',
    isLoggedIn: false,
    authId: null,
    authUserUnsubscribe: null,
    confirmedUser: false
  },
  getters: {
    authUser: (state) => {
      return state.user
    },
    getConfirmedUser: (state) => {
      const id = window.sessionStorage.getItem('USER_ID')
      if (id !== null) {
        state.authId = id
        return true
      }
      return false
    },
    getUserId: (state) => {
      const userId = window.sessionStorage.getItem('USER_ID')
      state.authId = userId
      return userId
    }
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    },
    setAuthId (state, id) {
      state.authId = id
      window.sessionStorage.setItem('USER_ID', id)
    },
    setConfirmedUser (state, bool = true) {
      state.confirmedUser = bool
    },
    setAuthUserUnsubscribe (state, unsubscribe) {
      state.authUserUnsubscribe = unsubscribe
    },
    setUserSession (state, session) {
      state.session = session
      window.sessionStorage.setItem('SESSION', session)
    },
    setUserAccessToken (state, accessToken) {
      state.accessToken = accessToken
      window.sessionStorage.setItem('ACCESS_TOKEN', accessToken)
    }
  },
  actions: {
    async signUp ({ state, dispatch }, { email, password }) {
      state.user.email = email
      const result = await dispatch('createData', { stream: `${STREAM}/signup`, body: { email, password, username: email } }, { root: true })
      if (result.success && result.id) {
        state.user.id = result.id
      }
      return result
    },
    async confirmSignUp ({ commit, dispatch }, { email, code }) {
      return await dispatch('createData', { stream: `${STREAM}/confirmsignup`, body: { email, code } }, { root: true })
    },
    async signIn ({ commit, state, dispatch }, { email, password }) {
      state.user.email = email
      const result = await dispatch('createData', { stream: `${STREAM}/signin`, body: { email, password } }, { root: true })
      if (result.session) {
        commit('setUserSession', result.session)
      } else if (result.accessToken) {
        commit('setUserAccessToken', state.accessToken)
        commit('setAuthId', state.user.id)
      }
      return result
    },
    async setupTOTP ({ commit, state, dispatch }) {
      if (state.user) {
        const body = { email: state.user.email }
        if (state.accessToken) {
          body.accessToken = state.accessToken
        } else {
          body.session = state.session
        }
        const result = await dispatch('createData', { stream: `${STREAM}/setuptotp`, body }, { root: true })
        if (result.error) {
          return result
        }
        if (result.session) {
          commit('setUserSession', result.session)
        }
        const params = new URL(result.url).searchParams
        return {
          code: params.get('secret'),
          url: result.url
        }
      } else {
        return null
      }
    },
    async verifySetupTOTP ({ commit, state, dispatch }, { code }) {
      const body = { totpToken: code, email: state.user.email }
      if (state.accessToken) {
        body.accessToken = state.accessToken
      } else {
        body.session = state.session
      }
      const result = await dispatch('createData', { stream: `${STREAM}/verifysetuptotp`, body }, { root: true })
      if (result.session) {
        commit('setUserSession', result.session)
      }
      if (result.success) {
        commit('setConfirmedUser', true)
      }
      return result
    },
    async confirmMFA ({ commit, state, dispatch }, { code }) {
      const result = await dispatch('createData', { stream: `${STREAM}/confirmmfa`, body: { email: state.user.email, code, session: state.session } }, { root: true })
      if (result.success) {
        commit('setConfirmedUser', true)
        commit('setAuthId', result.id)
        if (result.accessToken) {
          commit('setUserAccessToken', result.accessToken)
        }
      }
      return result
    },
    async resetPassword ({ commit, state, dispatch }, { oldPassword, newPassword }) {
      if (oldPassword && newPassword) {
        const result = await dispatch('createData', { stream: 'api/user/changepassword', body: { username: state.user.email, accessToken: state.accessToken, oldPassword, newPassword } }, { root: true })
        return result
      }
      return false
    },
    async signOut ({ commit }) {
      try {
        const keys = Object.keys(localStorage)
        keys.forEach(key => {
          if (key.includes('CognitoIdentityServiceProvider.')) {
            localStorage.removeItem(key)
          }
        })
        commit('setUser', null)
        commit('setAuthId', null)
        commit('setConfirmedUser', false)
        commit('setUserSession', null)
        commit('setUserAccessToken', null)
        window.sessionStorage.clear()
        window.location.reload()
        return true
      } catch (error) {
        return false
      }
    },
    async get ({ commit, dispatch }) {
      const userId = commit('getUserId')
      const user = await dispatch('users/get', {}, { root: true })
      commit('setUser', user)
      commit('setAuthId', userId)
      return user
    },
    async generateKeyPair ({ dispatch, state }) {
      const userId = await state.authId
      const privateKey = await dispatch('getByUserId', { database: DB_NAME, table: TABLE, id: KEY_NAMES.privateKey, userId }, { root: true })
      const publicKey = await dispatch('getByUserId', { database: DB_NAME, table: TABLE, id: KEY_NAMES.publicKey, userId }, { root: true })
      if (!privateKey || !publicKey) {
        return await generateKeys({ dispatch, state })
      }
    },
    async regenerateKeyPair ({ dispatch, state }) {
      return await generateKeys({ dispatch, state })
    },
    async createDeviceID ({ dispatch }) {
      if (!localStorage.getItem('DEVICE_ID')) {
        const deviceId = await dispatch('generateDeviceId', {}, { root: true })
        localStorage.setItem('DEVICE_ID', deviceId)
      }
    },
    async initAuthentication ({ commit, dispatch }) {
      try {
        const id = window.sessionStorage.getItem('USER_ID')
        if (id) {
          const session = window.sessionStorage.getItem('SESSION')
          const accessToken = window.sessionStorage.getItem('ACCESS_TOKEN')
          if (session) {
            commit('setUserSession', session)
          }
          if (accessToken) {
            commit('setUserAccessToken', accessToken)
          }
          const user = await dispatch('users/get', {}, { root: true })
          if (user) {
            commit('setUser', user)
            commit('setAuthId', id)
            commit('setConfirmedUser', true)
            return user
          }
        }
        return null
      } catch (error) {
        return null
      }
    }
  }
}

async function generateKeys ({ dispatch, state }) {
  const userId = window.sessionStorage.getItem('USER_ID')
  if (userId) {
    const keys = await generatePair(userId)
    const privateKey = await encrypt(state, keys.privateKey, userId)
    const publicKey = await encrypt(state, keys.publicKey, userId)
    if (privateKey && publicKey) {
      const request = {
        userId,
        publicKey: keys.publicKey,
        deviceId: localStorage.getItem('DEVICE_ID')
      }
      const requestResponse = await dispatch('createData', { stream: 'api/public/key/create', body: request }, { root: true })
      await dispatch('add', {
        database: DB_NAME,
        table: TABLE,
        record: { id: KEY_NAMES.privateKey, userId, data: privateKey }
      }, { root: true })
      await dispatch('add', {
        database: DB_NAME,
        table: TABLE,
        record: { id: KEY_NAMES.publicKey, userId, data: publicKey }
      }, { root: true })
      return requestResponse
    } else {
      throw new Error('Key saving was unsuccessful')
    }
  }
}
