import { Form, Field, ErrorMessage, defineRule, configure } from 'vee-validate'
import { required, email, min, max, url, confirmed, digits } from '@vee-validate/rules'
import { localize } from '@vee-validate/i18n'
// import { Auth } from 'aws-amplify'
export default (app) => {
  defineRule('required', required)
  defineRule('email', email)
  defineRule('min', min)
  defineRule('max', max)
  defineRule('url', url)
  defineRule('digits', digits)
  defineRule('password_mismatch', confirmed)
  defineRule('containsNumber', (value) => /\d/.test(value))
  defineRule('containsSpecialCharacter', (value) => /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value))
  defineRule('containsUppercase', (value) => /[A-Z]/.test(value))
  defineRule('validDate', (value) => {
    const hundredYearsAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 100))
    const now = new Date()
    value = new Date(value)
    if (value >= hundredYearsAgo && value <= now) {
      // Pass
      return true
    } else {
      // Fail
      return false
    }
  })
  defineRule('unique', async (value, args) => {
    let excluding
    if (Array.isArray(args)) {
      [excluding] = args
    } else {
      ({ excluding } = args)
    }
    if (value === excluding) return true
    // try {
    //   await Auth.signIn(value, '0')
    // } catch (error) {
    //   const code = error.code
    //   console.log(error)
    //   switch (code) {
    //     case 'UserNotFoundException':
    //       console.log('UserNotFoundException :>> ')
    //       return true
    //     default:
    //       console.log('UserFound :>> ')
    //       return false
    //   }
    // }
    return true
  })

  configure({
    generateMessage: localize('en', {
      messages: {
        required: '{field} is required',
        email: '{field} must be a valid email',
        min: '{field} must be a minimum of 0:{min} characters',
        max: '{field} must be a maximum of 0:{min} characters',
        unique: '{field} is already taken',
        url: '{field} must be a valid URL',
        digits: '{field} must be numeric',
        containsNumber: '{field} must contain at least 1 number',
        containsSpecialCharacter: '{field} must contain at least 1 special character',
        containsUppercase: '{field} must contain at least 1 uppercase letter',
        validDate: '{field} must be between today and a hundred years ago'
      }
    })
  })

  app.component('VeeForm', Form)
  app.component('VeeField', Field)
  app.component('VeeErrorMessage', ErrorMessage)
}
