import { findById, makeFetchItemAction, makeFetchItemsAction } from '@/helpers'
export default {
  namespaced: true,
  state: {
    items: []
  },
  getters: {
    identifier: (state, getters, rootState) => {
      return (id) => {
        const identifier = findById(state.items, id)
        if (!identifier) return {}
        return {
          ...identifier,
          get author () {
            return findById(rootState.users.items, identifier.userId)
          },
          get repliesCount () {
            if (!identifier.events) return 0
            return identifier.events.length - 1
          },
          get contributorsCount () {
            if (!identifier.contributors) return 0
            return identifier.contributors.length
          }
        }
      }
    }
  },
  actions: {
    fetchAllIdentifiers ({ commit }) {
      // TODO: Add Identifiers
      return null
    },
    fetchIdentifier: makeFetchItemAction({ emoji: '📄', resource: 'identifiers' }),
    fetchIdentifiers: makeFetchItemsAction({ emoji: '📄', resource: 'identifiers' })
  }
}
