const URL = process.env.VUE_APP_BACKEND_BASE_WS_URL

let socket = null
let connectionCounter = 0

export async function start (callbackError, callbackRequest, callbackResponse, callbackWebsocketClosed, device, client) {
  document.cookie = `access_token=${window.sessionStorage.getItem('ACCESS_TOKEN')}; path=/`
  socket = await new WebSocket(URL)
  connectionCounter++
  console.log('Connection counter: ' + connectionCounter)
  socket.addEventListener('open', (event) => {
    if (socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify({
        action: 'subscribe',
        deviceID: device,
        clientID: client
      }))
    }
  })
  setInterval(async () => {
    await socket.send(JSON.stringify({
      action: 'keep_open'
    }))
  }, 15000)
  socket.addEventListener('close', (event) => {
    callbackWebsocketClosed()
  })
  socket.addEventListener('error', callbackError)
  socket.addEventListener('message', (e) => {
    const data = JSON.parse(e.data)
    if (!data.message || data.message !== 'Ok') {
      if (data.action === 'notification_request') {
        callbackRequest(data)
      } else if (data.action === 'notification_response' || data.status === 'success') {
        callbackResponse(data)
      } else if (data.status === 'error') {
        callbackError(data)
      }
    }
  })
}

export async function notificationRequest (requestID, clientID) {
  return await socket.send(JSON.stringify({
    action: 'notification_request',
    requestID,
    clientID
  }))
}

export async function notificationDecision (requestID, clientID, approval, includeIds, details, ids) {
  return await socket.send(JSON.stringify({
    action: 'notification_response',
    requestID,
    clientID,
    approved: approval,
    includeIds,
    details,
    ids
  }))
}
