export default {
  namespaced: true,
  state: {
    items: [],
    values: null,
    options: []
  },
  getters: {},
  actions: {
    fetchCountries: async ({ dispatch }) => {
      return await dispatch('fetchData', { stream: 'country/list' }, { root: true })
    }
  }
}
