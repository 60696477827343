<template>
  <div class="app">
    <AppHead>
      <title>ProofOfID</title>
      <meta
        name="description"
        content="Digital ID for all your needs"
      >
    </AppHead>
    <the-navbar />
    <div class="container">
      <router-view
        v-show="showPage"
        :key="`${$route.path}${JSON.stringify($route.query)}`"
        @ready="onPageReady"
        @not-ready="loadPage"
      />
      <NotificationRequest />
      <AppSpinner v-show="!showPage" />
    </div>
  </div>
</template>

<script>
import NProgress from 'nprogress'
import TheNavbar from '@/components/TheNavbar'
import NotificationRequest from '@/components/NotificationRequest.vue'

export default {
  name: 'App',
  components: { TheNavbar, NotificationRequest },
  data () {
    return {
      showPage: false,
      showRequestModal: false,
      currentRequest: {
        requesterName: 'John Doe',
        identifiers: ['Identifier 1', 'Identifier 2'],
        requestedAt: new Date(),
        isActive: true,
        isApproved: false
      },
      timeoutId: null
    }
  },
  computed: {
    data () {
      // Access the requests from the store's state
      return this.$store.state.requests
    }
  },
  async created () {
    NProgress.configure({
      speed: 200,
      showSpinner: false
    })
    this.$router.beforeEach(() => {
      this.showPage = false
      NProgress.start()
    })
  },
  mounted () {
    // Set initial timeout
    this.resetTimeout()

    // Add event listeners to reset the timeout on user interaction
    document.addEventListener('mousemove', this.resetTimeout)
    document.addEventListener('click', this.resetTimeout)

    // Set a max session of 5 mins
    const timeoutDuration = process.env.VUE_APP_SESSION_TIMEOUT ? process.env.VUE_APP_SESSION_TIMEOUT : 300000
    setTimeout(() => {
      document.cookie = ''
      sessionStorage.clear()
      this.$router.go('/')
    }, timeoutDuration)
  },
  methods: {
    onPageReady () {
      this.showPage = true
      NProgress.done()
    },
    loadPage () {
      this.showPage = false
      NProgress.start()
    },
    resetTimeout () {
      // Clear existing timeout
      clearTimeout(this.timeoutId)

      // Set a new timeout
      this.timeoutId = setTimeout(() => {
        document.cookie = ''
        sessionStorage.clear()
        this.$router.go('/')
      }, 300000)
    }
  }
}
</script>

<style>
@import "assets/style.css";
@import "assets/modals.css";
@import "~nprogress/nprogress.css";
#nprogress .bar {
  background: #57ad8d !important;
}
</style>
