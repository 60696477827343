const STREAM = 'category'

export default {
  namespaced: true,
  state: {
    items: []
  },
  getters: {},
  actions: {
    fetchCategories: async ({ dispatch }) => {
      return await dispatch('fetchData', { stream: `${STREAM}/list` }, { root: true })
    }
  },
  mutations: {}
}
