import { encrypt, decrypt } from '@/helpers/encrypt'

const DB_NAME = 'PROOFOFID'
const TABLE = 'USERS'

export default {
  namespaced: true,
  state: {
    items: []
  },
  getters: {
    getUserById: async (state, dispatch) => async (id) => {
      return await decrypt(state, (await dispatch('get',
        {
          database: DB_NAME,
          table: TABLE,
          id
        },
        { root: true }
      )).data)
    },
    user: async (state, dispatch) => {
      return await decrypt(state, (await dispatch('get',
        {
          database: DB_NAME,
          table: TABLE,
          id: state.auth.authId
        },
        { root: true }
      )).data)
    }
  },
  actions: {
    async get ({ rootState, dispatch }, id) {
      let userId = rootState.auth.authId ? rootState.auth.authId : window.sessionStorage.getItem('USER_ID')
      if (!userId) userId = id
      if (userId) {
        let data = await dispatch('get',
          {
            database: DB_NAME,
            table: TABLE,
            id: userId
          },
          { root: true }
        )
        try {
          if (data.data) {
            data = await decrypt(rootState, data.data, userId)
          } else {
            data = await decrypt(rootState, data, userId)
          }
        } catch (error) {
          throw new Error('Your information was lost due to emptying cache. Please register again.')
        }
        if (data.data) {
          data = data.data
        }
        return data
      }
      return null
    },
    async add ({ commit, getters, dispatch, rootState }, user) {
      return await dispatch('add',
        {
          database: DB_NAME,
          table: TABLE,
          record: { id: user.id, data: await encrypt(rootState, user, user.id) }
        },
        { root: true }
      )
    },
    async update ({ commit, getters, dispatch, rootState }, user) {
      return await dispatch('update',
        {
          database: DB_NAME,
          table: TABLE,
          id: rootState.auth.authId,
          record: { id: rootState.auth.authId, data: encrypt(rootState, user) }
        },
        { root: true }
      )
    }
  },
  mutations: {}
}
