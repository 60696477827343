import { upsert, remove, docToResource } from '@/helpers'
export default {
  setItem (state, { resource, item }) {
    upsert(state[resource].items, docToResource(item))
  },
  delItem (state, { resource, item }) {
    remove(state[resource].items, docToResource(item))
  },
  // setDocument (state, { resource, id, item }) {
  //   const resourceIndex = state[resource].items.findIndex(item => item.id === id)
  //     state[resource].items[resourceIndex] = docToResource(item)
  //   }
  appendUnsubscribe (state, { unsubscribe }) {
    state.unsubscribes.push(unsubscribe)
  },
  clearAllUnsubscribes (state) {
    state.unsubscribes = []
  },
  clearItems (state, { modules = [] }) {
    modules.forEach(module => {
      state[module].items = []
    })
  }
}
