const STREAM = 'api/verification'

export default {
  namespaced: true,
  state: {
    items: []
  },
  getters: {},
  actions: {
    async getVerificationQuestions ({ dispatch }, formData) {
      return await dispatch('createData', { stream: `${STREAM}/questions`, body: formData }, { root: true })
    },
    async validateAnswers ({ dispatch }, { kountTransactionId, inquiryId, authenticationId, questionnaireId, questionAnswers }) {
      const data = {
        kountTransactionId,
        inquiryId,
        ipAddress: '',
        strategyAlias: 'CA_IDV',
        clientId: 'bitproof',
        data: {
          authenticationId,
          questionnaireId,
          questionAnswers
        }
      }
      return await dispatch('createData', { stream: `${STREAM}/questions/validate`, body: data }, { root: true })
    }
  }
}
