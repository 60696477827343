<template>
  <div
    v-if="showModal"
    class="modal-overlay"
  >
    <div class="modal">
      <h2 class="modal-title">
        Your session has ended
      </h2>
      <p class="modal-message">
        Please refresh page to continue.
      </p>
      <div class="modal-buttons">
        <button
          class="confirm-button"
          @click="reloadPage"
        >
          Reload
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showModal: Boolean
  },
  emits: ['confirm', 'cancel'],
  data () {
    return {
      confirmationCode: ''
    }
  },
  methods: {
    confirmSignUp () {
      if (this.confirmationCode) {
        this.$emit('confirm', {
          code: this.confirmationCode
        })
      }
    },
    cancelSignUp () {
      this.$emit('cancel')
    },
    reloadPage () {
      window.location.reload()
    }
  }
}
</script>

<style scoped>

</style>
