<template>
  <RequestApproval
    v-if="showRequestModal"
    :request="currentRequest"
    @on-close="closeRequestModal"
  />
  <AppNotifications />
  <WebsocketClosedModal :show-modal="showReloadModal" />
</template>

<script>
import { mapActions } from 'vuex'
import AppNotifications from '@/components/AppNotifications'
import RequestApproval from '@/components/RequestApproval'
import WebsocketClosedModal from '@/components/WebsocketClosedModal'
import useNotifications from '@/composables/useNotifications'
export default {
  name: 'RequestNotificarion',
  components: { AppNotifications, RequestApproval, WebsocketClosedModal },
  setup () {
    // Setting up notification handler
    const { addNotification } = useNotifications()

    return {
      addNotification
    }
  },
  data () {
    return {
      showRequestModal: false,
      showReloadModal: false,
      requestId: null
    }
  },
  computed: {
    authId () {
      return this.$store.state.auth.authId
    }
  },
  async created () {
    if (!localStorage.getItem('DEVICE_ID')) {
      await this.createDeviceID()
    }
    this.startNotificationListener({
      callbackError: this.onErrorReceived,
      callbackRequest: this.onRequestReceived,
      callbackResponse: this.onResponseReceived,
      callbackWebsocketClosed: this.onWebsocketClosed
    })
  },
  methods: {
    ...mapActions('requests', ['startNotificationListener', 'transferData']),
    ...mapActions('auth', ['createDeviceID']),
    onRequestReceived (data) {
      this.showRequestModal = true
      this.currentRequest = {
        ...data.request,
        ...data.publicKeyMeta
      }
      this.requestId = data.request.id
    },
    async onResponseReceived (data) {
      this.addNotification({ message: data.message, timeout: 3000 })
    },
    onErrorReceived (err) {
      this.addNotification({ message: `Error:\n${err.message}`, type: 'error', timeout: 3000 })
    },
    onWebsocketClosed () {
      this.showReloadModal = true
    },
    closeRequestModal () {
      this.showRequestModal = false
    }
  }
}
</script>
