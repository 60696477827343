import { uid } from 'uid'
import { encrypt, decrypt } from '@/helpers/encrypt'

const DB_NAME = 'PROOFOFID'
const TABLE = 'DOCUMENTS'

export default {
  namespaced: true,
  state: {
    items: []
  },
  getters: {
    document: (state, getters, dispatch, rootState) => {
      return async (id) => {
        return await decrypt(rootState,
          (await dispatch('get',
            {
              database: DB_NAME,
              table: TABLE,
              id
            },
            { root: true }
          )).data
        )
      }
    }
  },
  actions: {
    async create ({ commit, state, dispatch, rootState }, document) {
      // Updating document fields
      const documentFields = {
        id: uid(32) + rootState.auth.authId,
        // Getting current user
        uid: rootState.auth.authId,
        // Creating timestamp
        timestamp: new Date(),
        ...document
      }

      await dispatch('add',
        {
          database: DB_NAME,
          table: TABLE,
          record: { id: documentFields.id, data: await encrypt(rootState, documentFields) }
        },
        { root: true }
      )

      return documentFields
    },
    async updateDocument ({ commit, state, dispatch, rootState }, doc) {
      const newDoc = {
        ...doc,
        // Creating new timestamp
        timestamp: new Date()
      }

      newDoc.verified = false

      await dispatch('update',
        {
          database: DB_NAME,
          table: TABLE,
          id: newDoc.id,
          record: { id: newDoc.id, data: await encrypt(rootState, newDoc) }
        },
        { root: true }
      )

      return newDoc
    },
    async getItemsByCategoryID ({ commit, state, dispatch, rootState }, { categoryId }) {
      // Getting existing documents from local storage
      const documents = []
      const db = await dispatch('gets', { database: DB_NAME, table: TABLE }, { root: true })

      if (db) {
        db.forEach(document => {
          if (document.id.includes(rootState.auth.authId)) {
            const doc = decrypt(rootState, document.data)
            if (doc.category === categoryId) {
              documents.push(doc)
            }
          }
        })
      }

      return documents
    },
    async getItems ({ commit, state, dispatch, rootState }) {
      // Getting existing documents from local storage
      const documents = []
      const db = await dispatch('gets', { database: DB_NAME, table: TABLE }, { root: true })

      if (db) {
        db.forEach(document => {
          if (document.id.includes(rootState.auth.authId)) {
            const doc = decrypt(rootState, document.data)
            documents.push(doc)
          }
        })
      }

      return documents
    },
    async verify ({ commit, state, dispatch, rootState }, { id }) {
      let doc = await dispatch('get',
        {
          database: DB_NAME,
          table: TABLE,
          id
        },
        { root: true }
      )
      doc = decrypt(rootState, doc.data)
      doc.verified = true
      return await dispatch('update',
        {
          database: DB_NAME,
          table: TABLE,
          id: doc.id,
          record: { id: doc.id, data: await encrypt(rootState, doc) }
        },
        { root: true }
      )
    },
    async delete ({ commit, state, dispatch, rootState }, id) {
      return await dispatch('del',
        {
          database: DB_NAME,
          table: TABLE,
          id
        },
        { root: true }
      )
    },
    async getItem ({ commit, state, dispatch, rootState }, id) {
      return await decrypt(rootState,
        (await dispatch('get',
          {
            database: DB_NAME,
            table: TABLE,
            id
          },
          { root: true }
        )).data
      )
    }
  },
  mutations: {}
}
