const CONFIG = {
  headers: {
    'content-type': 'application/json'
  }
}

const URL = process.env.VUE_APP_BACKEND_BASE_URL

export async function fetchApiData (path) {
  return (await fetch(`${URL}/${path}`, { method: 'GET', ...CONFIG })).json()
}

export async function postApiData (path, data) {
  return (await fetch(`${URL}/${path}`, { method: 'POST', ...CONFIG, body: JSON.stringify(data) })).json()
}
