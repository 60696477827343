// Styles
import '@mdi/font/css/materialdesignicons.css'

// Composables
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Define the custom theme
const customTheme = {
  dark: false, // If you want to enable dark mode, set to true
  colors: {
    primary: '#570f8b',
    secondary: '#d19cfb',
    gradientStart: '#4f0acf',
    gradientEnd: '#a404e1',
    black: '#1e1e1e',
    gray: '#8b8b8b',
    blue: '#4895ef',
    red: '#ff3e3e',
    text: '#333333'
  }
}

export default createVuetify({
  theme: {
    defaultTheme: 'customTheme',
    themes: {
      customTheme
    }
  },
  defaults: {
    VBtn: {
      color: 'primary'
    }
  },
  components,
  directives
})
