<template>
  <span :title="humanFriendlyDate">
    {{ diffForHumans }}
  </span>
</template>

<script>
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedDate from 'dayjs/plugin/localizedFormat'
dayjs.extend(relativeTime)
dayjs.extend(localizedDate)

export default {
  props: {
    timestamp: {
      required: true,
      type: [Number, Object]
    }
  },
  computed: {
    normalizedTimestamp () {
      // Output: 1629345600
      return this.timestamp?.seconds || this.timestamp
    },
    diffForHumans () {
      // Output: a month ago
      return dayjs.unix(this.normalizedTimestamp).fromNow()
    },
    humanFriendlyDate () {
      // Output: Thu, Aug 19, 2021 4:00 AM
      return dayjs.unix(this.normalizedTimestamp).format('llll')
    }
  }
}
</script>

<style scoped>

</style>
