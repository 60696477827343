<template>
  <div class="form-group">
    <label :for="name">{{ label }}</label>
    <multiselect
      v-model="selectedValue"
      :options="options"
      :placeholder="placeholder"
      :multiple="false"
      :close-on-select="closeOnSelect"
      :searchable="searchable"
      :required="required"
      class="multiselect-input"
      v-bind="$attrs"
      @input="updateModel"
    />
    <VeeErrorMessage
      :name="name"
      class="form-error"
    />
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    Multiselect
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      required: true
    },
    name: { type: String, required: true },
    label: { type: String, default: '' },
    options: { type: Array, required: true },
    placeholder: { type: String, default: '' },
    closeOnSelect: { type: Boolean, default: true },
    searchable: { type: Boolean, default: true },
    required: { type: Boolean, default: true }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      selectedValue: this.modelValue
    }
  },
  methods: {
    updateModel (value) {
      // Convert the selected value to a single string
      this.selectedValue = value && typeof value === 'object' ? value[value.length - 1] : value
      this.$emit('update:modelValue', this.selectedValue)
    }
  }
})
</script>

  <style scoped>
  .form-group {
    display: flex;
    flex-direction: column;
  }

  .multiselect-input {
    margin-top: 5px;
  }

  .form-error {
    color: #dc3545;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
  </style>
