const STREAM = 'document-type'

export default {
  namespaced: true,
  state: {
    items: [],
    values: null,
    options: []
  },
  getters: {},
  actions: {
    fetchDocumentTypes: async ({ dispatch }) => {
      return await dispatch('fetchData', { stream: `${STREAM}/list` }, { root: true })
    }
  }
}
